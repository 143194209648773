import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';

import { CheckBoxContainer, HouseholdExclusionModelImpl, HouseholdExclusionType } from '../utils/utils';
import { UaopFlags } from '../../DryRunDialog';
import { exhaustiveCheck } from '../../../../utils/utils';

interface HouseholdExclusionStepProps {
    selectedUaop: UaopFlags;
    hExclusion: HouseholdExclusionType;
    onSelectionChange: (flag: HouseholdExclusionType) => void;
}

interface IOptionsBuilder {
    withNone: (disabled?: boolean) => IOptionsBuilder;
    withOne: (disabled?: boolean) => IOptionsBuilder;
    withTwo: (disabled?: boolean) => IOptionsBuilder;
    withThree: (disabled?: boolean) => IOptionsBuilder;
    build: () => any[];
}

class OptionsBuilder implements IOptionsBuilder {
    private readonly output = [];

    withNone = (disabled?: boolean) => {
        this.output.push({ ...HouseholdExclusionModelImpl.NONE, disabled });
        return this;
    };

    withOne = (disabled?: boolean) => {
        this.output.push({ ...HouseholdExclusionModelImpl.ONE_MONTH, disabled });
        return this;
    };

    withTwo = (disabled?: boolean) => {
        this.output.push({ ...HouseholdExclusionModelImpl.TWO_MONTHS, disabled });
        return this;
    };

    withThree = (disabled?: boolean) => {
        this.output.push({ ...HouseholdExclusionModelImpl.THREE_MONTHS, disabled });
        return this;
    };

    build = () => {
        return this.output;
    };
}

const getHouseholdExclusionOptions = (selectedUaop: UaopFlags) => {
    const optionsBuilder = new OptionsBuilder();

    switch (selectedUaop) {
        case UaopFlags.AssistanceOverlapSkip7:
        case UaopFlags.IncrementalAnyAmount:
        case UaopFlags.IncrementalAnyAmountWithAllowedOverlap:
        case UaopFlags.IncrementalShiftPeriodV2:
        case UaopFlags.IncrementalDeltaAmountV2:
            return optionsBuilder.withNone().withOne().withTwo().withThree().build();
        case UaopFlags.TotalAnyAmount:
        case UaopFlags.TotalDeltaAmountV2:
        case UaopFlags.NoneHistorical:
        case UaopFlags.NoneIntendedAssistanceOverlap:
            return optionsBuilder.withNone().withOne(true).withTwo(true).withThree(true).build();
        default:
            exhaustiveCheck(selectedUaop);
    }
};

const HouseholdExclusionStep: FC<HouseholdExclusionStepProps> = (props) => {
    const [periods, setPeriods] = useState([]);

    useEffect(() => {
        props.onSelectionChange('NONE');
        setPeriods(getHouseholdExclusionOptions(props.selectedUaop));
    }, [props.selectedUaop]);

    return (
        <div className={'wfp-assisted-exclusion-step'}>
            <h6
                style={{
                    marginTop: '32px',
                }}
            >
                Do you want to exclude households who have been assisted in the recent past with the selected assistance
                categories?
            </h6>
            <div>
                {periods.map((p) => {
                    return (
                        <CheckBoxContainer key={p.id} marginTop="0px">
                            <label
                                className={classNames({
                                    active: props.hExclusion === p.id,
                                    'text-secondary': p.disabled,
                                })}
                            >
                                <input
                                    checked={props.hExclusion === p.id}
                                    className={'mr-2'}
                                    disabled={p.disabled}
                                    name="validate-category"
                                    onChange={() => props.onSelectionChange(p.id)}
                                    type="radio"
                                />
                                {p.label}
                            </label>
                        </CheckBoxContainer>
                    );
                })}
            </div>
        </div>
    );
};

HouseholdExclusionStep.displayName = 'HouseholdExclusionStep';

export { HouseholdExclusionStep };
