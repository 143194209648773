import classNames from 'classnames';
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { getAsyncTask } from '../../../apiClient';
import { ClearIcon } from '../../../icons/Clear.svg';
import { ActionCreators as AsyncActionCreators, AsyncTasksTypes } from '../../../utils/asyncTasks';
import { useInterval } from '../../../utils/hooks/IntervalHook';
import { UploadStatuses } from '../../../utils/import';
import { ActionCreators as BeneficiaryActionCreators, UploadTaskState } from '../../beneficiaries';
import { UaopFlags } from '../DryRunDialog';
import { useStepperContext } from './StepperContext';
import { CategoryAssistanceSelectionStep } from './steps/CategoryAssistanceSelectionStep';
import { ConfirmationStep } from './steps/ConfirmationStep';
import { DeduplicationOptionsStep } from './steps/DeduplicationOptionsStep';
import { FileUploadStep } from './steps/FileUploadStep';
import { HouseholdExclusionStep } from './steps/HouseholdExclusionStep';
import { HouseholdExclusionModelImpl, HouseholdExclusionType, ModalBackground } from './utils/utils';
import { AdditionalCategoriesStep } from './steps/AdditionalCategoriesStep';
import { MetaCategories } from '../../../country/types';

interface StepHeader {
    id: number;
    label: string;
    enable: boolean;
    skip: boolean;
}

interface StepContent {
    id: number;
    content: JSX.Element;
}

interface StepperDialogProps {
    onClose?: () => void;
}

const StepperDialog: FC<StepperDialogProps> = (props: StepperDialogProps) => {
    const dispatch = useDispatch();
    const [selectedAssistanceCategory, setSelectedAssistanceCategory] = useState<string>();
    const [selectedDeduplicationCategories, setSelectedDeduplicationCategories] = useState<string[]>([]);
    const [allowedAdditionalDeduplicationCategories, setAllowedAdditionalDeduplicationCategories] = useState<string[]>(
        []
    );
    const [selectedFlag, setSelectedFlag] = useState<UaopFlags>();
    const [hExclusion, setHExclusion] = useState<HouseholdExclusionType>('NONE');
    const [selectedConfirmations, setSelectedConfirmations] = useState([]);
    const [uploadTaskStatus, setUploadTaskStatus] = useState(new UploadTaskState(UploadStatuses.pending));
    const [selectedFile, setSelectedFile] = useState<File>(null);
    const uploadTaskState = useSelector((state: State) => state.uploadTaskState);
    const beneficiariesImportMapping = useSelector((state: State) => state.appConfig.beneficiariesImportMapping);
    const [refreshHandle, setRefreshHandle] = useState(null);
    const { isNextStepDisable, enableNextStep } = useStepperContext();
    const [currentStep, setCurrentStep] = useState(0);
    const { categoriesToDeduplicationFlags, categoryConfig } = useSelector(
        (state: State) => state.appConfig.entitlementsConfig
    );

    const [isInterCategoryDeduplicationStepEnabled, setIsInterCategoryDeduplicationStepEnabled] = useState(false);
    const [isHouseholdExclusionStepEnabled, setIsHouseholdExclusionStepEnabled] = useState(false);
    const currentCategoryConfig = useMemo(() => {
        return categoryConfig.find((category) => category.category === selectedAssistanceCategory);
    }, [categoryConfig, selectedAssistanceCategory]);

    useEffect(() => {
        if (!currentCategoryConfig?.isAssistedHouseholdExclusionEnabled) {
            setIsHouseholdExclusionStepEnabled(false);
        } else {
            const isEnabled =
                ![UaopFlags.TotalAnyAmount, UaopFlags.NoneHistorical, UaopFlags.NoneIntendedAssistanceOverlap].includes(
                    selectedFlag
                ) && currentCategoryConfig?.isAssistedHouseholdExclusionEnabled;

            setIsHouseholdExclusionStepEnabled(isEnabled);
        }
    }, [selectedFlag, selectedAssistanceCategory]);

    useEffect(() => {
        if (currentCategoryConfig?.skipInterCategoryDeduplication) {
            setIsInterCategoryDeduplicationStepEnabled(false);
        } else {
            const isEnabled = allowedAdditionalDeduplicationCategories.length > 0;
            setIsInterCategoryDeduplicationStepEnabled(isEnabled);
        }
    }, [allowedAdditionalDeduplicationCategories, currentCategoryConfig?.skipInterCategoryDeduplication]);

    const selectedMetaCategory = useMemo(() => {
        return categoryConfig.find((category) => category.category === selectedAssistanceCategory)
            ?.metaCategory as MetaCategories;
    }, [selectedAssistanceCategory]);

    const shouldShowInterCategoryInformation = useMemo(() => {
        return (
            ![UaopFlags.NoneHistorical, UaopFlags.NoneIntendedAssistanceOverlap].includes(selectedFlag) &&
            (isInterCategoryDeduplicationStepEnabled ||
                currentCategoryConfig?.forcedInterCategoriesForDeduplication?.length > 0)
        );
    }, [
        isInterCategoryDeduplicationStepEnabled,
        currentCategoryConfig?.forcedInterCategoriesForDeduplication,
        selectedFlag,
    ]);

    const allowedAdditionalCategoriesToSelect = (selectedAssistanceCategory: string, flag: UaopFlags) => {
        if (!flag || flag === UaopFlags.NoneHistorical || flag === UaopFlags.NoneIntendedAssistanceOverlap) return [];
        const categoryConfigForSelectedCategory = categoryConfig.find(
            (cc) => cc.category === selectedAssistanceCategory
        );
        if (!categoryConfigForSelectedCategory) return [];
        const combinedForcedAndAllowed = Array.from(
            new Set<string>([
                ...(categoryConfigForSelectedCategory.allowedCategoriesForSharedDeduplication || []),
                ...(categoryConfigForSelectedCategory.forcedInterCategoriesForDeduplication || []),
            ])
        );

        return combinedForcedAndAllowed.filter((c) => {
            const flagsForCategory = categoriesToDeduplicationFlags[c];
            const categoryConfigForFilteredCategory = categoryConfig.find((cc) => cc.category === c);
            if (!flagsForCategory || !categoryConfigForFilteredCategory) return false;

            if (categoryConfigForFilteredCategory.readonly) return false;

            if (flag === UaopFlags.IncrementalAnyAmountWithAllowedOverlap || flag === UaopFlags.IncrementalAnyAmount) {
                return (
                    flagsForCategory.includes(UaopFlags.IncrementalAnyAmount) ||
                    flagsForCategory.includes(UaopFlags.IncrementalAnyAmountWithAllowedOverlap)
                );
            }

            return flagsForCategory.includes(flag);
        });
    };

    const stepHeaders = useMemo<StepHeader[]>(() => {
        const assistanceCategoryAndUaopHeaders = [
            {
                label: 'Assistance Category',
                enable: true,
                skip: false,
            },
            {
                label: 'UAOP',
                enable: true,
                skip: false,
            },
        ];

        const interCategoryAndAssistedHouseholdHeaders = [
            {
                label: 'Inter-Category',
                enable: isInterCategoryDeduplicationStepEnabled && allowedAdditionalDeduplicationCategories?.length > 0,
                skip: !isInterCategoryDeduplicationStepEnabled,
            },
            {
                label: 'Assisted Households Exclusion',
                enable: isHouseholdExclusionStepEnabled,
                skip: !isHouseholdExclusionStepEnabled,
            },
        ];

        const confirmationAndFileUploadHeaders = [
            {
                label: 'Confirmation',
                enable: true,
                skip: false,
            },
            {
                label: 'File upload',
                enable: true,
                skip: false,
            },
        ];

        return [
            ...assistanceCategoryAndUaopHeaders,
            ...interCategoryAndAssistedHouseholdHeaders,
            ...confirmationAndFileUploadHeaders,
        ].map((header, index) => ({ ...header, id: index }));
    }, [currentStep, isInterCategoryDeduplicationStepEnabled, isHouseholdExclusionStepEnabled]);

    const loadTaskState = async () => {
        if (uploadTaskState.error) {
            setUploadTaskStatus(new UploadTaskState(UploadStatuses.finished, null, uploadTaskState.error));
        } else if (uploadTaskState.task) {
            const response = await getAsyncTask(uploadTaskState.task.type, uploadTaskState.task.id);
            const taskStatus =
                response.task.finishedAt && response.task.children && response.task.children.length > 0
                    ? UploadStatuses.finished
                    : UploadStatuses.pending;
            const taskState = new UploadTaskState(taskStatus, response.task);
            setUploadTaskStatus(taskState);
            if (taskStatus !== UploadStatuses.pending) {
                setRefreshHandle(false);
            }
        }
    };

    useInterval(loadTaskState, !!refreshHandle ? 2000 : null);

    const stepContents: StepContent[] = [
        {
            id: 0,
            content: (
                <CategoryAssistanceSelectionStep
                    resetUaopFlag={() => setSelectedFlag(null)}
                    selectedCategory={selectedAssistanceCategory}
                    setSelectedCategory={(category) => {
                        setSelectedAssistanceCategory(category);
                        setAllowedAdditionalDeduplicationCategories(
                            allowedAdditionalCategoriesToSelect(selectedAssistanceCategory, null)
                        );
                        setSelectedDeduplicationCategories([]);
                    }}
                />
            ),
        },
        {
            id: 1,
            content: (
                <DeduplicationOptionsStep
                    onSelectChange={(flag) => {
                        setSelectedFlag(flag);
                        setAllowedAdditionalDeduplicationCategories(
                            allowedAdditionalCategoriesToSelect(selectedAssistanceCategory, flag)
                        );
                        setSelectedDeduplicationCategories(
                            currentCategoryConfig?.forcedInterCategoriesForDeduplication?.length > 0
                                ? currentCategoryConfig?.forcedInterCategoriesForDeduplication
                                : []
                        );
                    }}
                    selectedCategory={selectedAssistanceCategory}
                    selectedFlag={selectedFlag}
                    selectedMetaCategory={selectedMetaCategory}
                />
            ),
        },
        {
            id: 2,
            content: (
                <AdditionalCategoriesStep
                    categoriesToSelect={allowedAdditionalDeduplicationCategories}
                    deduplicationCategories={selectedDeduplicationCategories}
                    forcedCategoriesToDeduplicate={currentCategoryConfig?.forcedInterCategoriesForDeduplication || []}
                    recommendedCategoryForSharedDeduplication={
                        currentCategoryConfig?.recommendedCategoryForSharedDeduplication
                    }
                    selectedAssistanceCategory={selectedAssistanceCategory}
                    selectedUaop={selectedFlag}
                    setDeduplicationCategories={(categories) => {
                        setSelectedDeduplicationCategories(categories);
                    }}
                />
            ),
        },
        {
            id: 3,
            content: (
                <HouseholdExclusionStep
                    hExclusion={hExclusion}
                    onSelectionChange={(flag) => setHExclusion(flag)}
                    selectedUaop={selectedFlag}
                />
            ),
        },
        {
            id: 4,
            content: (
                <ConfirmationStep
                    allowedAdditionalDeduplicationCategories={allowedAdditionalDeduplicationCategories}
                    customExampleFilePerFlag={currentCategoryConfig?.customExampleFilePerFlag}
                    hExclusion={hExclusion}
                    isEnterpriseCategory={currentCategoryConfig?.isEnterpriseCategory || false}
                    onConfirmationChange={(c) => setSelectedConfirmations(c)}
                    selectedAssistanceCategory={selectedAssistanceCategory}
                    selectedConfirmations={selectedConfirmations}
                    selectedDeduplicationCategories={selectedDeduplicationCategories}
                    selectedFlag={selectedFlag}
                    showDeduplicationCategoriesConfirmation={shouldShowInterCategoryInformation}
                    showHouseholdExclusionConfirmation={isHouseholdExclusionStepEnabled}
                />
            ),
        },
        {
            id: 5,
            content: (
                <FileUploadStep
                    hExclusion={hExclusion}
                    selectedAssistanceCategory={selectedAssistanceCategory}
                    selectedDeduplicationCategories={selectedDeduplicationCategories}
                    selectedFile={selectedFile}
                    selectedFlag={selectedFlag}
                    setRefreshHandle={setRefreshHandle}
                    setSelectedFile={setSelectedFile}
                    setUploadTaskStatus={setUploadTaskStatus}
                    showDeduplicationCategoriesConfirmation={shouldShowInterCategoryInformation}
                    showHouseholdExclusionConfirmation={isHouseholdExclusionStepEnabled}
                    taskState={uploadTaskStatus}
                />
            ),
        },
    ];

    const onNextStep = async () => {
        if (currentStep === stepHeaders.length - 1) {
            if (!selectedFile) return;
            if (!uploadTaskStatus.task) return;

            await dispatch(
                BeneficiaryActionCreators.importBeneficiaries({
                    data: selectedFile,
                    uaopFlag: selectedFlag,
                    assistanceCategory: selectedAssistanceCategory,
                    deduplicationCategories: [selectedAssistanceCategory, selectedDeduplicationCategories],
                    overlapPeriod: HouseholdExclusionModelImpl[hExclusion].value,
                    schedule: true,
                    dryRun: false,
                    mapping: beneficiariesImportMapping,
                    startIndex: 2,
                    dryRunAsyncTaskId: uploadTaskStatus.task.id,
                })
            );
            dispatch(AsyncActionCreators.loadAsyncTasksList(1, 10, AsyncTasksTypes.IMPORT_BENEFICIARIES));
            props.onClose();
            return;
        }

        let nextStepIncrementCount = 1;
        while (!stepHeaders[currentStep + nextStepIncrementCount].enable) {
            nextStepIncrementCount++;
        }

        setCurrentStep((prev) => (prev < stepHeaders.length - 1 ? prev + nextStepIncrementCount : prev));
    };

    const onPrevStep = () => {
        enableNextStep();
        let prevStepIncrementCount = 1;
        while (!stepHeaders[currentStep - prevStepIncrementCount].enable) {
            prevStepIncrementCount++;
        }
        setCurrentStep((prev) => (prev > 0 ? prev - prevStepIncrementCount : prev));
    };

    const shouldBlockRunImportButton = () => {
        if (uploadTaskStatus.status !== UploadStatuses.finished) {
            return true;
        }
        if (!uploadTaskStatus.task) {
            return true;
        } else if (uploadTaskStatus.error) {
            return true;
        } else if (uploadTaskStatus.task && uploadTaskStatus.task.errors && uploadTaskStatus.task.errors.length > 0) {
            return true;
        }
        return false;
    };

    return (
        <div className={'wfp-stepper'}>
            <ModalBackground />
            <Modal.Dialog
                dialogClassName={'modal-medium'}
                style={{ overflow: 'auto', marginTop: '100px', paddingBottom: '150px' }}
            >
                <Modal.Header className={'align-items-center'}>
                    <span>File upload prerequisites</span>
                    <button className="close-button" onClick={props.onClose} type="button">
                        <ClearIcon />
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <div className={'wfp-stepper__nav'}>
                        {stepHeaders
                            .filter((sh) => !sh.skip)
                            .map((sh, id) => {
                                return (
                                    <React.Fragment key={id}>
                                        <div
                                            className={classNames('wfp-stepper__nav__item mb-2', {
                                                active: currentStep === sh.id,
                                                disable: !sh.enable,
                                            })}
                                        >
                                            <div className={'item-id'}>{id + 1}</div>
                                            <div className={'item-label'}>{sh.label}</div>
                                        </div>
                                        {id < stepHeaders.length - 1 && <span className={'linker'} />}
                                    </React.Fragment>
                                );
                            })}
                    </div>

                    <div className={'wfp-stepper__steps'}>
                        {stepContents.map((sc) => {
                            return currentStep === sc.id ? (
                                <div className={'wfp-steps__step'} key={sc.id}>
                                    {sc.content}
                                </div>
                            ) : null;
                        })}
                    </div>
                </Modal.Body>

                <Modal.Footer className={'modal-footer'}>
                    <div>
                        <button className="wfp-btn" onClick={props.onClose} type="button">
                            Cancel
                        </button>
                        {currentStep === 5 && selectedFile && (
                            <button
                                className={classNames('wfp-btn--warning ml-2')}
                                onClick={() => {
                                    setSelectedFile(null);
                                    setUploadTaskStatus(new UploadTaskState(UploadStatuses.pending));
                                    setRefreshHandle(false);
                                }}
                                type="button"
                            >
                                Reset file
                            </button>
                        )}
                    </div>

                    <div>
                        {currentStep !== 0 && (
                            <button className="wfp-btn--primary" onClick={onPrevStep} type="button">
                                Go Back
                            </button>
                        )}
                        {stepHeaders.length - 1 === currentStep ? (
                            <button
                                className={classNames('wfp-btn--primary ml-2')}
                                disabled={shouldBlockRunImportButton()}
                                onClick={onNextStep}
                                type="button"
                            >
                                Run Import
                            </button>
                        ) : (
                            <button
                                className={classNames('wfp-btn--primary ml-2')}
                                disabled={isNextStepDisable}
                                onClick={onNextStep}
                                type="button"
                            >
                                {currentStep === 3 ? 'Yes' : 'Next'}
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
};

StepperDialog.displayName = 'StepperDialog';

export { StepperDialog };
