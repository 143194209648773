import React from 'react';
import Switch from 'rc-switch';
import clonedeep from 'lodash/cloneDeep';
import moment from 'moment';
import { Calendar } from 'react-date-range';
import { Simulate } from 'react-dom/test-utils';
import Select from 'react-select';

import { ExtendedManager } from './managers';
import { ExternalValidationError, Form, Input, ValidationContext } from '../utils/inputs';
import { displayTime } from '../utils/utils';
import { ManagerStatus } from './manager';
import { AuthState, hasFeatureAccess } from '../login/auth';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { DownloadPermissionsButton } from '../permissions-files/DownloadPermissionsButton';
import { ManagerPermission } from '../permission-profiles/permission';
import { PermissionProfilesHOCProps, withPermissionProfiles } from '../permission-profiles/use-permission-profiles';
import PermissionsList from '../permission-profiles/permissions-list';
import { SelectOption } from '../utils/FilterTypes';
import { getAgencies } from '../apiClient';
import { DisplayPublicGpgKey } from './helpers/DisplayPublicGpgKey';

import input = Simulate.input;

interface Props extends PermissionProfilesHOCProps {
    authState: AuthState;
    manager: ExtendedManager;
    validationError?: ExternalValidationError;
    resetPasswordRequest: (manager: ExtendedManager) => void;
    resetOtpRequest: (manager: ExtendedManager) => void;
    resetOtpPasswordRequest: (manager: ExtendedManager) => void;
    onSaved: (manager) => void;
    authorizeManager: (managerId: string) => void;
    cancelPark: (managerId: string) => void;
    editDisabled: boolean;
    postEnabled: boolean;
    thisManagerParked: boolean;
    managerId: string;
}

interface LocalState {
    manager: ExtendedManager;
    changeExpirationDate: boolean;
    changeStartDate: boolean;
    showPermissions: boolean;
    agencies: any[];
    isAgencySelectEnabled: boolean;
}

const UNICODE_DOWN_ARROW = '\u2193';
const UNICODE_UP_ARROW = '\u2191';

class ManagerDetails extends React.Component<Props, LocalState> {
    _validationContext = new ValidationContext();

    constructor(props: Props) {
        super(props);
        this.state = {
            manager: props.manager,
            changeExpirationDate: false,
            changeStartDate: false,
            showPermissions: false,
            agencies: [],
            isAgencySelectEnabled: true,
        };
    }

    static getDerivedStateFromProps(props: Props, state: LocalState) {
        if (props.manager.id && props.manager.id === state.manager.id) {
            return null;
        }

        let manager = clonedeep(props.manager);
        if (manager.id && !manager.expirationDate) {
            const defaultExpirationDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            manager.expirationDate = moment(defaultExpirationDate).format('YYYY-MM-DD');
        }

        if (!manager.id) {
            const managerState = clonedeep(state.manager);

            if (!managerState.expirationDate) {
                const defaultExpirationDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
                managerState.expirationDate = moment(defaultExpirationDate).format('YYYY-MM-DD');
            }
            if (!managerState.startDate) {
                const defaultStartDate = new Date();
                managerState.startDate = moment(defaultStartDate).format('YYYY-MM-DD');
            }

            manager = { ...manager, ...managerState };
        }

        return { manager };
    }

    async componentDidMount() {
        const agencies = await getAgencies();
        this.setState({ agencies: agencies.map((agency) => ({ label: agency, value: agency })) });
    }

    componentDidUpdate() {
        if (this.props.validationError) {
            this._validationContext.pushError(this.props.validationError);
        }
    }

    profileNameHandler = (profile) => {
        return (e) => {
            e.persist();

            const managerState = { ...this.state.manager };
            if (profile.isGlobal) {
                managerState.agency = 'ALL';
            } else if (managerState.agency === 'ALL') {
                managerState.agency = undefined;
            }

            this.setState({
                ...this.state,
                isAgencySelectEnabled: !profile.isGlobal,
                manager: {
                    ...managerState,
                    profile,
                },
            });
        };
    };

    renderInputField(options: {
        readonly: boolean;
        value: string;
        name: string;
        required: boolean;
        type: string;
        placeholder?: string;
        isFocused?: boolean;
    }) {
        return (
            <div className="col-sm-8">
                {options.readonly && <label className="normal">{options.value}</label>}
                {!options.readonly && (
                    <Input
                        className="form-control"
                        context={this._validationContext}
                        isFocused={options.isFocused}
                        name={options.name}
                        onChange={this._handleInputChange.bind(this)}
                        placeholder={options.placeholder || ''}
                        required={options.required}
                        type={options.type}
                        value={options.value}
                    />
                )}
            </div>
        );
    }

    SelectField = (props: { label: string; name: string; options: SelectOption[]; isAgencySelectEnabled: boolean }) => {
        return (
            <div className="wfp-form--group row">
                <label className="col-sm-4 ta-right">{props.label}</label>
                <div className="col-sm-8">
                    {this.props.editDisabled ? (
                        this.state.manager[props.name]
                    ) : (
                        <>
                            <Select
                                autoFocus
                                id="state-select"
                                isDisabled={!props.isAgencySelectEnabled}
                                name="selected-state"
                                onChange={(selectedOption) => {
                                    this.setState((state) => ({
                                        ...state,
                                        manager: {
                                            ...state.manager,
                                            [props.name]: selectedOption,
                                        },
                                    }));
                                }}
                                options={props.options}
                                required={true}
                                searchable={false}
                                value={
                                    typeof this.state.manager[props.name] === 'string'
                                        ? {
                                              label: this.state.manager[props.name],
                                              value: this.state.manager[props.name],
                                          }
                                        : this.state.manager[props.name]
                                }
                            />
                        </>
                    )}
                </div>
            </div>
        );
    };

    changeExpirationDate(date) {
        const newDate = moment(date).format('YYYY-MM-DD');
        const newManager = this.state.manager;
        newManager.expirationDate = newDate;
        this.setState({ manager: newManager, changeExpirationDate: false });
    }

    changeStartDate = (date) => {
        const newDate = moment(date).format('YYYY-MM-DD');
        const newManager = this.state.manager;
        newManager.startDate = newDate;
        this.setState({ ...this.state, manager: newManager, changeStartDate: false });
    };

    showPermissionsHandler = () => {
        this.setState({
            ...this.state,
            showPermissions: !this.state.showPermissions,
        });
    };

    render() {
        const isExistingUser = this.state.manager.id !== null && this.state.manager.id !== undefined;
        const hasPermissionForOtp =
            hasFeatureAccess(this.props.authState, ManagerPermission.changeOtpStatus) ||
            hasFeatureAccess(this.props.authState, ManagerPermission.userOTPReset);
        const hasPermissionForOtpPassword =
            hasFeatureAccess(this.props.authState, ManagerPermission.userOTPReset) &&
            hasFeatureAccess(this.props.authState, ManagerPermission.userPasswordReset);
        const saveTitle = isExistingUser ? 'Park changes' : 'Park new user';
        const isBlockedOrExpired =
            moment(this.props.manager.expirationDate).toDate() < new Date() ||
            this.props.manager.status === ManagerStatus.blocked;
        const startDate = this.state.manager.startDate ? this.state.manager.startDate : 'None';
        const canEditDates = this.props.editDisabled || this.state.manager.id === this.props.managerId;
        const hasHiddenProfile = this.state.manager.profile?.hidden;

        return (
            <Form className="wfp-form" context={this._validationContext} onSubmit={this._saveChanges.bind(this)}>
                {isExistingUser && (
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">WFP User ID</label>
                        {this.renderInputField({
                            readonly: true,
                            value: this.state.manager.id,
                            name: 'id',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                )}
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">First Name</label>
                    {this.renderInputField({
                        readonly: this.props.editDisabled,
                        value: this.state.manager.firstName,
                        name: 'firstName',
                        required: true,
                        type: 'text',
                        isFocused: true,
                    })}
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Last Name</label>
                    {this.renderInputField({
                        readonly: this.props.editDisabled,
                        value: this.state.manager.lastName,
                        name: 'lastName',
                        required: true,
                        type: 'text',
                    })}
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Email</label>
                    {this.renderInputField({
                        readonly: this.props.editDisabled,
                        value: this.state.manager.email,
                        name: 'email',
                        required: true,
                        type: 'email',
                    })}
                </div>
                <this.SelectField
                    isAgencySelectEnabled={this.state.isAgencySelectEnabled}
                    label="Agency"
                    name="agency"
                    options={this.state.agencies}
                />
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Public Key</label>
                    {this.props.editDisabled ? (
                        <div className="col-sm-8">
                            <DisplayPublicGpgKey publicGpgKey={this.state.manager.publicKey} />
                        </div>
                    ) : (
                        this.renderInputField({
                            readonly: false,
                            value: this.state.manager.publicKey,
                            name: 'publicKey',
                            required: false,
                            type: 'textarea',
                        })
                    )}
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Phone Number</label>
                    {this.renderInputField({
                        readonly: this.props.editDisabled,
                        value: this.state.manager.phoneNumber,
                        name: 'phoneNumber',
                        required: true,
                        type: 'text',
                    })}
                </div>
                {this.props.manager.id &&
                    this.props.manager.authorizedAt &&
                    !isBlockedOrExpired &&
                    this.props.manager.status === ManagerStatus.active &&
                    hasFeatureAccess(this.props.authState, ManagerPermission.userPasswordReset) && (
                        <div className="wfp-form--group row">
                            <label className="col-sm-4 ta-right">Password</label>
                            <div className="col-sm-8 pv1">
                                <a onClick={() => this.props.resetPasswordRequest(this.props.manager)}>
                                    Reset Password
                                </a>
                            </div>
                        </div>
                    )}
                {this.props.manager && this.props.manager.authorizedAt && (
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Password Blocked</label>
                        {this.renderInputField({
                            readonly: true,
                            value: this.props.manager.isPasswordBlocked ? 'Yes' : 'No',
                            name: 'passwordBlocked',
                            required: true,
                            type: 'text',
                        })}
                    </div>
                )}
                {hasPermissionForOtp && (
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">OTP Status</label>
                        <div className="col-sm-8 pv1">
                            <Switch
                                checked={this.state.manager.otpEnabled}
                                disabled={
                                    this.props.editDisabled ||
                                    !hasFeatureAccess(this.props.authState, ManagerPermission.otpStatusChangePark)
                                }
                                onChange={this._handleOTPChange.bind(this)}
                            />
                            <br />
                            {this.props.manager.otpEnabled &&
                                !isBlockedOrExpired &&
                                this.props.manager.authorizedAt &&
                                hasFeatureAccess(this.props.authState, ManagerPermission.userOTPReset) &&
                                this.props.manager.id && (
                                    <div className={'col-sm-8 pv1 ta-left'}>
                                        <a onClick={() => this.props.resetOtpRequest(this.props.manager)}>Reset OTP</a>
                                    </div>
                                )}
                            {this.props.manager.otpEnabled &&
                                !isBlockedOrExpired &&
                                this.props.manager.authorizedAt &&
                                this.props.manager.id &&
                                hasPermissionForOtpPassword && (
                                    <div className={'col-sm-8 pv1 ta-left'}>
                                        <a onClick={() => this.props.resetOtpPasswordRequest(this.props.manager)}>
                                            Reset OTP and Password
                                        </a>
                                    </div>
                                )}
                        </div>
                        {/*{!isExistingUser && hasFeatureAccess(this.props.authState, ManagerPermission.changeOtpStatus) && <div className="col-sm-8 pv1">*/}
                        {/*<Switch checked={this.state.otpEnabled} onChange={this._handleOtpSignUp.bind(this)}/>*/}
                        {/*</div>}*/}
                    </div>
                )}

                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Active</label>
                    <div className="col-sm-8 pv1">
                        {
                            <Switch
                                checked={this.state.manager.status === ManagerStatus.active}
                                disabled={this.props.editDisabled}
                                onChange={this._handleActiveChange.bind(this)}
                            />
                        }
                    </div>
                </div>
                {this.state.manager.id && (
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Status</label>
                        <div className="col-sm-8">
                            <label className="normal">
                                Parked at {displayTime(this.state.manager.createdAt)}
                                <div>
                                    by{' '}
                                    {!this.state.manager.createdByManager
                                        ? 'System'
                                        : this.state.manager.createdByManager.name}
                                </div>
                            </label>

                            {this.props.manager.authorizedAt ? (
                                <label className="normal">
                                    Posted at {displayTime(this.props.manager.authorizedAt)}
                                    <div>
                                        by{' '}
                                        {!this.state.manager.authorizedByManager
                                            ? 'System'
                                            : this.state.manager.authorizedByManager.name}
                                    </div>
                                </label>
                            ) : this.props.manager.cancelledAt ? (
                                <label className="normal">
                                    Cancelled at {displayTime(this.props.manager.cancelledAt)}
                                    {this.props.manager.cancelledByManager && (
                                        <div>
                                            by{' '}
                                            {!this.state.manager.cancelledByManager
                                                ? 'System'
                                                : this.state.manager.cancelledByManager.name}
                                        </div>
                                    )}
                                </label>
                            ) : this.props.thisManagerParked ? (
                                <label>
                                    {' '}
                                    <span style={{ color: 'red' }}>*</span>You cannot post it, because you parked it.
                                </label>
                            ) : (
                                this.props.postEnabled && (
                                    <span>
                                        <a onClick={this._authorizeManager.bind(this)}>Post</a>{' '}
                                        <a onClick={this._cancelManager}>Cancel</a>
                                    </span>
                                )
                            )}
                            {/*{this.props.thisManagerParked && !this.state.authorizedAt &&*/}
                            {/*<label> <span style={{ color: "red" }}>*</span>You cannot post it, because you parked it.</label>*/}
                            {/*}*/}
                        </div>
                    </div>
                )}
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Start Date</label>
                    {canEditDates ? (
                        <label className="col-sm-4">{startDate}</label>
                    ) : (
                        <div className="col-sm-8">
                            <input
                                onClick={() =>
                                    this.setState({
                                        changeStartDate: !this.state.changeStartDate,
                                    })
                                }
                                readOnly={true}
                                style={{ marginLeft: 10 }}
                                type={'text'}
                                value={this.state.manager.startDate ? this.state.manager.startDate : ''}
                            />
                            {this.state.changeStartDate && (
                                <div
                                    className={'dateFilterWithApply'}
                                    style={{
                                        position: 'absolute',
                                        zIndex: 2,
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <Calendar
                                        className={'rdrDateRangePickerWrapper'}
                                        date={
                                            this.state.manager.startDate
                                                ? moment(this.state.manager.startDate)
                                                : moment(new Date())
                                        }
                                        minDate={new Date()}
                                        onChange={this.changeStartDate}
                                    />
                                    <div className="wfp-form--actions text-center">
                                        <button
                                            className="wfp-btn ml3"
                                            onClick={() =>
                                                this.setState({
                                                    changeStartDate: false,
                                                })
                                            }
                                            style={{ margin: 5 }}
                                            type="button"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Expiration Date</label>
                    {canEditDates ? (
                        <label className="col-sm-4">{this.state.manager.expirationDate}</label>
                    ) : (
                        <div className="col-sm-8">
                            <input
                                onClick={() =>
                                    this.setState({
                                        changeExpirationDate: !this.state.changeExpirationDate,
                                    })
                                }
                                readOnly={true}
                                style={{ marginLeft: 10 }}
                                type={'text'}
                                value={this.state.manager.expirationDate ? this.state.manager.expirationDate : ''}
                            />
                            {this.state.changeExpirationDate && (
                                <div
                                    className={'dateFilterWithApply'}
                                    style={{
                                        position: 'absolute',
                                        zIndex: 2,
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <Calendar
                                        className={'rdrDateRangePickerWrapper'}
                                        date={
                                            this.state.manager.expirationDate
                                                ? moment(this.state.manager.expirationDate)
                                                : null
                                        }
                                        minDate={new Date()}
                                        onChange={this.changeExpirationDate.bind(this)}
                                    />
                                    <div className="wfp-form--actions text-center">
                                        <button
                                            className="wfp-btn ml3"
                                            onClick={() =>
                                                this.setState({
                                                    changeExpirationDate: false,
                                                })
                                            }
                                            style={{ margin: 5 }}
                                            type="button"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {this.state.manager.id && (
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Permissions File</label>
                        <span className="col-sm-8">
                            {this.state.manager.permissionsFiles && this.state.manager.permissionsFiles.length ? (
                                <div className="pv1">
                                    <DownloadPermissionsButton
                                        asyncTaskId={this.state.manager.permissionsFiles[0].id}
                                        fileName={this.state.manager.permissionsFiles[0].fileName}
                                        hasAccess={this.state.manager.permissionsFiles[0].hasAccess}
                                    />
                                </div>
                            ) : (
                                <label className="normal">No File</label>
                            )}
                        </span>
                    </div>
                )}
                <div className="wfp-form--group row">
                    <label className="col-sm-4 ta-right">Profile</label>
                    <span className="col-sm-8">
                        {this.props.editDisabled || hasHiddenProfile ? (
                            <label className="normal">{this.state.manager.profile?.name}</label>
                        ) : (
                            <div className="BB-two-column-layout">
                                {this.props.permissionProfiles.items.map((profile) => (
                                    <label key={profile.id}>
                                        <input
                                            checked={this.state.manager.profile?.id === profile.id}
                                            name="profile"
                                            onChange={this.profileNameHandler(profile)}
                                            type="radio"
                                            value={profile.name}
                                        />
                                        {profile.name}
                                    </label>
                                ))}
                            </div>
                        )}
                    </span>
                </div>
                {
                    <div className="wfp-form--group row">
                        <label className="col-sm-4 ta-right">Permissions</label>
                        <div className="col-sm-8">
                            <label
                                className="normal"
                                onClick={this.showPermissionsHandler}
                                style={{ cursor: 'pointer' }}
                            >
                                {this.state.showPermissions
                                    ? `Hide Permissions ${UNICODE_UP_ARROW}`
                                    : `Show Permissions ${UNICODE_DOWN_ARROW}`}
                            </label>
                            {this.state.showPermissions && (
                                <PermissionsList
                                    editDisabled={false}
                                    permissions={this.state?.manager.profile?.permissions}
                                />
                            )}
                        </div>
                    </div>
                }
                {!this.props.editDisabled && (
                    <div className="wfp-form--actions text-center">
                        <div className="col-sm-8 col-sm-offset-4">
                            <button className="wfp-btn--primary" type="submit">
                                {saveTitle}
                            </button>
                        </div>
                    </div>
                )}
            </Form>
        );
    }

    _handleActiveChange(checked) {
        const newManager = this.state.manager;
        newManager.status = checked ? ManagerStatus.active : ManagerStatus.blocked;
        this.setState({ manager: newManager });
    }

    _handleOtpSignUp() {
        const newManager = this.state.manager;
        newManager.otpEnabled = !newManager.otpEnabled;
        this.setState({ manager: newManager });
    }

    _handleOTPChange(checked) {
        const newManager = this.state.manager;
        newManager.otpEnabled = checked;
        this.setState({ manager: newManager });
    }

    private _handleInputChange({ target }) {
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const manager = this.state.manager;
        manager[target.name] = value;
        this.setState({ manager });
    }

    private _saveChanges(event) {
        event.preventDefault();
        const { ...newManager } = this.state.manager;
        if (newManager.agency)
            newManager.agency = typeof newManager.agency === 'string' ? newManager.agency : newManager.agency.value;

        this.props.onSaved(newManager);
    }

    private _authorizeManager(event) {
        this.props.authorizeManager(this.state.manager.id);
        event.preventDefault();
    }

    private _cancelManager = (event) => {
        this.props.cancelPark(this.state.manager.id);
        event.preventDefault();
    };
}

export default withPermissionProfiles(ManagerDetails);
