import { isEqual } from 'lodash';
import clonedeep from 'lodash/cloneDeep';

import Action from '../action';
import {
    cancelManagerPark,
    getManagers,
    authorizeManager,
    createManager,
    editManager,
    requestManagerPasswordReset,
    editPersonalData,
    exportActivityLog,
    getManagersNickNames,
    getManagerActivityLogs,
    exportUsers,
    changePassword,
    requestManagerOtpReset,
    managerOtpChangeStatus,
    requestManagerOtpPasswordReset,
    uploadPermissionsFile,
    beneficiarySupportUploadPermissionsFile,
} from '../apiClient';
import Manager, { ManagerStatus } from './manager';
import { ExternalValidationError } from '../utils/inputs';
import { successAction, errorAction } from '../utils/notifications';
import { ActionTypes as LoginActionTypes } from '../login/auth';
import NamedId from '../utils/NamedId';
import { EntityUpdateReference } from '../authorization/taskAuthorizations';
import { AsyncTask, isUrl } from '../utils/asyncTasks';
import { ActivityLogPageType } from '../activityLogs/ActivityLogExport';
import { PagedState } from '../utils/paging';
import { compose } from '../utils/utils';
import { Filter } from '../utils/FilterTypes';
import PermissionProfile from '../permission-profiles/profile';
import { routerHelperActions } from '@wfp-common/store/routerHelperSlice';

const messages = {
    permissionUploadSuccess: (fileName: string): string => `${fileName} was uploaded successfully`,
    permissionUploadError: (fileName): string => `${fileName} could not be uploaded`,
};

export class ExtendedManager {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    otpEnabled = true;
    status: ManagerStatus = ManagerStatus.active;
    createdAt?: string;
    createdByManager: NamedId;
    authorizedByManager?: NamedId;
    authorizedAt?: string;
    pendingEntityUpdates?: EntityUpdateReference[];
    expirationDate: string;
    cancelledByManager?: NamedId;
    cancelledAt?: string;
    isPasswordBlocked: boolean;
    permissionsFiles: Array<AsyncTask> = [];
    profile?: PermissionProfile;
    startDate?: string;
    agency: any;
    publicKey?: string;
}

export interface ManagersProps {
    items: ExtendedManager[];
    sortingOptions?: { sortingOrder: string; sortingColumn: string };
}

export interface ManagerActivityLog {
    lastLogin?: string;
    lastLogout?: string;
    lastPasswordChange?: string;
    lastOtpChange?: string;
}

export class ManagersState implements ManagersProps {
    items: ExtendedManager[];
    activityLogs: PagedState<any>;
    validationError?: ExternalValidationError = null;
    managerNickNameList: Array<ManagerNickName> = [];
    permissionsFiles?: AsyncTask[];
    sortingOptions?: { sortingColumn: string; sortingOrder: string };

    constructor(
        managers: ExtendedManager[] = [],
        validationError: ExternalValidationError = null,
        activityLog: PagedState<any> = new PagedState<any>(),
        managerNickNameList: Array<ManagerNickName> = [],
        permissionsFiles: Array<AsyncTask> = [],
        sortingOptions: { sortingColumn: string; sortingOrder: string } = {
            sortingColumn: 'firstName',
            sortingOrder: 'ASC',
        }
    ) {
        this.items = managers;
        this.validationError = validationError;
        this.activityLogs = activityLog;
        this.managerNickNameList = managerNickNameList;
        this.permissionsFiles = permissionsFiles;
        this.sortingOptions = sortingOptions;
    }
}

export class ManagerNickName {
    id = '';
    firstName: string;
    lastName: string;
    email: string;
    authorizedAt: Date;
}

export enum ActivityType {
    login = 'login',
    logout = 'logout',
    passwordChange = 'password-change',
    otpSecretChange = 'otp-secret-change',
    otpSecretReset = 'otp-secret-reset',
    entityUpdatePark = 'entity-update-park',
    entityUpdatePost = 'entity-update-post',
    managerDetails = 'manager-details-update',
    phoneNumberChange = 'phone-number-change',
    userParked = 'userPark',
    userPost = 'userPost',
}

export const ActionTypes = {
    managersLoaded: 'Managers.loaded',
    managerLoaded: 'Manager.loaded',
    managerAuthorized: 'Manager.authorized',
    managerCancelled: 'Manager.cancelled',
    activityLogLoaded: 'Manager.activityLogLoaded',
    managersNickNameLoaded: 'Manager.managersNickNameLoaded',
    permissionFileUploaded: 'Manager.permissionFileUploaded',
    managerSortingChange: 'Manager.sortingChanger',
};

function managersWithAuthorizedManager(
    managers: ExtendedManager[],
    authorizedManager: ExtendedManager
): ExtendedManager[] {
    const newManagers: ExtendedManager[] = clonedeep(managers);
    const index = newManagers.findIndex((manager) => manager.id === authorizedManager.id);
    if (index >= 0) {
        newManagers[index] = authorizedManager;
    }

    return newManagers;
}

function managersWithCancelledManager(
    managers: ExtendedManager[],
    cancelledManager: ExtendedManager
): ExtendedManager[] {
    const newManagers: ExtendedManager[] = clonedeep(managers);
    const index = newManagers.findIndex((manager) => manager.id === cancelledManager.id.toString());
    if (index >= 0) {
        newManagers[index] = cancelledManager;
    }
    return newManagers;
}

export function managersReducer(state: ManagersState = new ManagersState(), action: Action) {
    switch (action.type) {
        case ActionTypes.managersLoaded:
            return new ManagersState(
                action.payload,
                null,
                state.activityLogs,
                state.managerNickNameList,
                state.permissionsFiles,
                state.sortingOptions
            );
        case ActionTypes.managersNickNameLoaded:
            return new ManagersState(state.items, null, state.activityLogs, action.payload);
        case ActionTypes.managerAuthorized:
            const managers = managersWithAuthorizedManager(state.items, action.payload.manager);
            return new ManagersState(managers);
        case ActionTypes.managerCancelled:
            const newManagers = managersWithCancelledManager(state.items, action.payload.manager);
            return new ManagersState(newManagers);
        case ActionTypes.activityLogLoaded:
            return new ManagersState(
                state.items,
                null,
                action.payload,
                state.managerNickNameList,
                state.permissionsFiles,
                state.sortingOptions
            );
        case ActionTypes.permissionFileUploaded:
            const permissionsFiles = [action.payload, ...state.permissionsFiles];
            return new ManagersState(
                state.items,
                null,
                state.activityLogs,
                state.managerNickNameList,
                permissionsFiles,
                state.sortingOptions
            );
        case ActionTypes.managerSortingChange:
            return new ManagersState(
                state.items,
                null,
                state.activityLogs,
                state.managerNickNameList,
                state.permissionsFiles,
                action.payload
            );
        default:
            return state;
    }
}

function editManagerRequestParams(manager: ExtendedManager) {
    const requestParams: { startDate?: string } = {};
    if (manager.startDate) {
        requestParams.startDate = `${manager.startDate}T00:00:00.000Z`;
    }

    return {
        ...requestParams,
        id: manager.id,
        firstName: manager.firstName,
        lastName: manager.lastName,
        email: manager.email,
        phoneNumber: manager.phoneNumber,
        status: manager.status,
        otpEnabled: manager.otpEnabled,
        profile: manager.profile,
        expirationDate: manager.expirationDate + 'T23:59:59.999Z',
        agency: manager.agency,
        publicKey: manager.publicKey,
    };
}

function createManagerRequestParams(manager: ExtendedManager) {
    const params = editManagerRequestParams(manager);
    delete params.id;
    delete params.profile;
    return { ...params, permissionProfileId: manager.profile.id };
}

function isValidDate(dateString) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) {
        return false;
    } // Invalid format
    const d = new Date(dateString);
    if (!d.getTime() && d.getTime() !== 0) {
        return false;
    } // Invalid date
    return d.toISOString().slice(0, 10) === dateString;
}

function actionForError(error) {
    if (error.status === 422) {
        return errorAction('Conflicting access features');
    } else if (error.status === 409) {
        return errorAction('Conflicting data');
    }

    throw error; // let it be handled generically via errorMiddleware
}

export const ActionCreators = {
    getManagers: (sortingColumn, sortingOrder, filters?: Filter[]) => {
        return (dispatch) =>
            getManagers(sortingColumn, sortingOrder, filters).then((response) =>
                dispatch({
                    type: ActionTypes.managersLoaded,
                    payload: response,
                })
            );
    },
    sortManagers(sortingOptions) {
        return (dispatch) => {
            dispatch({
                type: ActionTypes.managerSortingChange,
                payload: sortingOptions,
            });
        };
    },
    createManager(manager: ExtendedManager) {
        return (dispatch) => {
            if (!manager.agency) {
                window.scrollTo(0, 0);
                return dispatch(errorAction('Agency can not be empty'));
            }

            const isExpirationDateInvalid = !manager.expirationDate || !isValidDate(manager.expirationDate);
            const isStartDateInvalid = !manager.startDate || !isValidDate(manager.startDate);
            const isExpirationDateLater = new Date(manager.startDate) > new Date(manager.expirationDate);

            switch (true) {
                case isExpirationDateInvalid:
                    window.scrollTo(0, 0);
                    return dispatch(errorAction('Invalid expiration date'));
                case isStartDateInvalid:
                    window.scrollTo(0, 0);
                    return dispatch(errorAction('Invalid start date'));
                case isExpirationDateLater:
                    window.scrollTo(0, 0);
                    return dispatch(errorAction('Start date can not be later than expiration date'));
            }

            const params = createManagerRequestParams(manager);
            delete params.id;
            return createManager(params)
                .then(() => {
                    dispatch(routerHelperActions.makeRedirect('/admin'));
                    dispatch(successAction('User parked successfully.'));
                })
                .catch((err) => {
                    const contentType = err.headers.get('content-type');
                    if (err.text && contentType && contentType.includes('text')) {
                        err.text().then((errorText) => {
                            window.scrollTo(0, 0);
                            dispatch(errorAction(errorText));
                        });
                    } else {
                        window.scrollTo(0, 0);
                        dispatch(actionForError(err));
                    }
                });
        };
    },

    editManager(prevVersion: Manager, manager: ExtendedManager) {
        return (dispatch) => {
            const noChanges = isEqual(Object.assign({}, prevVersion, manager), prevVersion);

            if (noChanges) {
                window.scrollTo(0, 0);
                return dispatch(errorAction('No changes to be parked.'));
            }

            if (!manager.agency) {
                window.scrollTo(0, 0);
                return dispatch(errorAction('Agency can not be empty'));
            }

            if (!manager.expirationDate || !isValidDate(manager.expirationDate)) {
                window.scrollTo(0, 0);
                return dispatch(errorAction('Invalid expiration date'));
            }
            if (manager.startDate) {
                if (!isValidDate(manager.startDate)) {
                    window.scrollTo(0, 0);
                    return dispatch(errorAction('Invalid start date'));
                }

                if (new Date(manager.startDate) > new Date(manager.expirationDate)) {
                    window.scrollTo(0, 0);
                    return dispatch(errorAction('Start date can not be higher than expiration date'));
                }
            }

            const params = editManagerRequestParams(manager);
            // const tooLongParamsCount = Object.keys(params).filter((key) => params[key].length > 255).length;
            // if (tooLongParamsCount) {
            //     window.scrollTo(0, 0);
            //     dispatch(errorAction('Given value is too long'));
            //     return;
            // }
            if (prevVersion.otpEnabled !== manager.otpEnabled) {
                return managerOtpChangeStatus(params)
                    .then(() => {
                        dispatch(routerHelperActions.makeRedirect('/admin'));
                        dispatch(successAction('User changes parked successfully.'));
                    })
                    .catch((err) => dispatch(actionForError(err)));
            }
            return editManager(params)
                .then(() => {
                    dispatch(routerHelperActions.makeRedirect('/admin'));
                    dispatch(successAction('User changes parked successfully.'));
                })
                .catch((err) => {
                    if (err.text) {
                        err.text().then((errorText) => {
                            window.scrollTo(0, 0);
                            dispatch(errorAction(errorText));
                        });
                    } else {
                        window.scrollTo(0, 0);
                        dispatch(actionForError(err));
                    }
                });
        };
    },

    editPersonalData(manager: ExtendedManager) {
        const params = editManagerRequestParams(manager);
        return (dispatch) =>
            editPersonalData(params).then((manager) => {
                dispatch({
                    type: LoginActionTypes.reloadUserSuccess,
                    payload: manager,
                });
                dispatch(successAction('User changes saved successfully.'));
            });
    },
    changePassword(oldPass: string, newPass: string) {
        return (dispatch) =>
            changePassword(oldPass, newPass)
                .then(() => {
                    dispatch(successAction(`Password changed`));
                    setTimeout(function () {
                        window.location.replace(window.location.origin);
                    }, 1000);
                })
                .catch((err) => {
                    err.json().then(({ type }) => {
                        if (type) {
                            dispatch(errorAction(`Failed to change password - ${type}`));
                        }
                    });
                });
    },
    resetPasswordRequest(manager: Manager) {
        return (dispatch) =>
            requestManagerPasswordReset(manager, 'email')
                .then((res) => {
                    if (isUrl(res)) {
                        window.location.replace(res);
                    } else {
                        dispatch(successAction('Password reset mail sent successfully.'));
                    }
                })
                .catch(() => {
                    dispatch(errorAction('User is blocked. Unblock user before resetting password.'));
                });
    },
    resetOtpPasswordRequest(manager: Manager) {
        return (dispatch) =>
            requestManagerOtpPasswordReset(manager, 'email').then((res) => {
                if (isUrl(res)) {
                    window.location.replace(res);
                } else {
                    dispatch(successAction('OTP and password reset mail sent successfully.'));
                }
            });
    },

    resetOtpRequest(manager: Manager) {
        return (dispatch) =>
            requestManagerOtpReset(manager, 'email').then((res) => {
                if (isUrl(res)) {
                    window.location.replace(res);
                } else {
                    dispatch(successAction('Otp set mail sent successfully.'));
                }
            });
    },

    authorizeManager(managerId: string) {
        return (dispatch) =>
            authorizeManager(managerId).then((manager) => {
                dispatch(successAction(`User authorized successfully.`));
                dispatch({
                    type: ActionTypes.managerAuthorized,
                    payload: manager,
                });
            });
    },

    exportActivityLog(managerId: string, options: any, filter: any) {
        return (dispatch) =>
            exportActivityLog(managerId, ActivityLogPageType.Manager, options, filter).catch((err) => {
                if (err.status === 423) {
                    dispatch(
                        errorAction(
                            `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                        )
                    );
                }
            });
    },

    loadManagersNickNames() {
        return (dispatch) =>
            getManagersNickNames().then((managers) => {
                dispatch({
                    type: ActionTypes.managersNickNameLoaded,
                    payload: managers,
                });
            });
    },
    loadActivityLogs(managerId, page, limit) {
        return (dispatch) =>
            getManagerActivityLogs(managerId, page, limit).then((result) => {
                dispatch({
                    type: ActionTypes.activityLogLoaded,
                    payload: result,
                });
            });
    },
    exportUsers(columns: any[], id: string) {
        return (dispatch) =>
            exportUsers(columns, id).catch((err) => {
                if (err.status === 423) {
                    dispatch(
                        errorAction(
                            `Export task limit exceeded. Please wait until one of the scheduled exports finished`
                        )
                    );
                }
            });
    },
    cancelPark(id: string) {
        return (dispatch) =>
            cancelManagerPark(id).then((manager) => {
                dispatch({
                    type: ActionTypes.managerCancelled,
                    payload: manager,
                });
                dispatch(successAction('Admin cancelled successfully.'));
            });
    },
    uploadPermissionsFile(id: string, file: File) {
        return async (dispatch) => {
            await uploadPermissionsFile(id, file)
                .then(() => {
                    compose<string, void>(dispatch, successAction, messages.permissionUploadSuccess)(file.name);
                })
                .catch(() => {
                    compose<string, void>(dispatch, errorAction, messages.permissionUploadError)(file.name);
                });

            const managers = await getManagers();
            dispatch({
                type: ActionTypes.managersLoaded,
                payload: managers,
            });
        };
    },
};
