import React from 'react';

import {
    CountrySpecificType,
    DefaultValueType,
    MetaCategories,
    MetaCategoryAndCountrySpecificType,
    SupportedCountries,
} from './types';
import { UaopFlags } from '../beneficiaries/fileUploadPages/DryRunDialog';
import { Paragraph } from '../beneficiaries/fileUploadPages/file-uploader-stepper/utils/utils';

// TODO -> key in UaopFlags marked as optional but after removing deprecated flag it should be required
type UaopFlagDescriptionMapping = {
    [key in UaopFlags]?: CountrySpecificType & MetaCategoryAndCountrySpecificType & DefaultValueType;
};

export const uaopFlagDescriptionMapping: UaopFlagDescriptionMapping = {
    [UaopFlags.IncrementalAnyAmount]: {
        default: (
            <Paragraph>
                Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the indicated
                assistance period in my file, regardless of the amount and overlap period of the existing assistance, no
                additional assistance is shown as being made available by me. For example, if existing assistance to a
                household is $3 from Jan-Jun, and my assistance is $6 from Apr-Sept, completely skip my assistance.
            </Paragraph>
        ),
        customPerMetaCategoryAndCountry: {
            [MetaCategories.NFI]: {
                [SupportedCountries.ukraine]: (
                    <Paragraph>
                        Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                        indicated assistance period in my file, regardless of the amount, no additional assistance is
                        shown as being made available by me. For example, if existing assistance to a household is $3,
                        and my assistance is $6 for an overlapping period, completely skip my assistance.
                    </Paragraph>
                ),
            },
        },
        customPerCountry: {
            [SupportedCountries.syr_gos]: (
                <Paragraph>
                    Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                    indicated assistance period in my file, regardless of the amount and overlap period of the existing
                    assistance, no additional assistance is shown as being made available by me. For example, if
                    existing assistance to a household is $3 from Jan-Mar, and my assistance is $6 from Mar-May,
                    completely skip my assistance.
                </Paragraph>
            ),
            [SupportedCountries.syr_nws]: (
                <Paragraph>
                    Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                    indicated assistance period in my file, regardless of the amount and overlap period of the existing
                    assistance, no additional assistance is shown as being made available by me. For example, if
                    existing assistance to a household is $3 from Jan-Apr, and my assistance is $6 from Apr-Jul,
                    completely skip my assistance.
                </Paragraph>
            ),
        },
    },
    [UaopFlags.TotalAnyAmount]: {
        default: (
            <Paragraph>
                Apply UAOP to my file, so if assistance already exists for the households in my file for any amount and
                any period (past, present, or future), my assistance is prevented. For example, if I have indicated I
                want to assist a household with $6 from Apr-Jun, and the existing assistance is for $3 from Jan-Mar,
                prevent my assistance.
            </Paragraph>
        ),
    },
    [UaopFlags.TotalDeltaAmountV2]: {
        default: (
            <Paragraph>
                Apply UAOP to my file, given the sum of all existing assistance for each of the households in my file
                for any amount and any period (past, present, or future), the remaining amount, up to the maximum
                recommended amount for the category, is loaded. For example, if I have indicated I want to assist a
                household with $5 from Apr-Sept, the maximum recommended amount for the category is $6, and the sum of
                all existing assistance for that household is $3, mark my assistance as $3 ($6 - $3) from Apr-Sept.
            </Paragraph>
        ),
    },
    [UaopFlags.IncrementalDeltaAmountV2]: {
        customPerCountry: {
            [SupportedCountries.syr_gos]: (
                <Paragraph>
                    Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the
                    indicated assistance period in my file, only the difference between the amounts is loaded. For
                    example, if I have indicated I want to assist a household with $5 from Mar-May, and it has existing
                    assistance of $3 from Jan-Mar, mark my assistance as $2 ($5 - $3) from Mar-May.
                </Paragraph>
            ),
        },
        default: (
            <Paragraph>
                Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the indicated
                assistance period in my file, only the remaining amount, up to the maximum recommended amount for the
                category, is loaded. For example, if I have indicated I want to assist a household with $5 from
                Apr-Sept, the maximum recommended amount for the category is $6, and it has existing assistance of $3
                from Jan-Jun, mark my assistance as $3 ($6 - $3) from Apr-Sept.
            </Paragraph>
        ),
    },
    [UaopFlags.IncrementalShiftPeriodV2]: {
        default: (
            <Paragraph>
                Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the indicated
                assistance period in my file, shift my assistance period to start the day after the existing assistance
                period ends. For example, if I have indicated to assist a household with $6 from Mar-May, and existing
                assistance for that household is $3 from Jan-Mar, shift my assistance so that it shows as $6 from Apr-
                Jun.
                <br />
                <br />
                Note: To reduce uncertainty about assistance yet to be available too far in the future, the system will
                shift your assistance start date for a maximum of 1 month into the future from the start date in your
                file. For example, if your loading assistance from Feb-Apr, and the existing assistance is from Jan-Mar,
                BB will not load your assistance as this would require a shift of 2 months (more than the 1-month max
                shift limit).
                <br />
                <br />
                Note: If your assistance is shifted, you commit to ensuring your FSP or distribution point makes the
                assistance available to households as close as possible to the newly indicated start date.
            </Paragraph>
        ),
    },
    [UaopFlags.IncrementalAnyAmountWithAllowedOverlap]: {
        default: (
            <Paragraph>
                Apply UAOP to my file, so if my assistance overlaps with any existing assistance during the indicated
                assistance period in my file, regardless of the amount, the assistance may overlap by a maximum of 1
                month. If there is more than a 1 month overlap period of existing assistance, no additional assistance
                is shown as being made available by me. For example, if existing assistance to a household is $3 from
                Jan-Mar, and my assistance is $6 from Mar-May (1-month overlap), allow my assistance. If existing
                assistance to a household is $3 from Jan-Mar, and my assistance is $6 from Feb-Apr (2-month overlap),
                completely skip my assistance.
            </Paragraph>
        ),
    },
    [UaopFlags.AssistanceOverlapSkip7]: {
        default: (
            <Paragraph>
                Deduplicate the entire assistance value if two or more organizations provide assistance for 7 or more
                consecutive days. Overlapping assistance from multiple organizations will only be flagged if it occurs
                in the future; past overlaps will not be flagged. This rule applies specifically to historical data
                loaded by an organization where the assistance period is in the future (e.g., if an organization has
                already distributed help to a beneficiary from January 1st, covering them until the end of January). The
                first come, first served rule should be followed, meaning the timing of assistance file uploads is
                crucial. If two or more organizations upload assistance for the same beneficiary on the same day, the
                order of the uploads will determine which assistance is considered valid. Multiple overlaps within a
                single day will be counted as one. For instance, if more than two organizations provide assistance to
                the same beneficiary on January 15th, it will be recorded as a single overlap (this logic may be changed
                in the future)
            </Paragraph>
        ),
    },
    [UaopFlags.NoneHistorical]: {
        default: (
            <Paragraph>
                Note: Only for this UAOP option, multiple assistance periods can exist in your file.
                <br />
                <br />
                Do not perform any UAOP. I’m loading data about assistance that has already been made available to
                households in the past (before I started using BB). I am loading this assistance to BB so that other
                organizations and/or I may apply UAOP against it in the future.
            </Paragraph>
        ),
    },
    [UaopFlags.NoneIntendedAssistanceOverlap]: {
        default: (
            <Paragraph>
                Do not perform any UAOP as I’m loading data about assistance that has not yet been made available to
                households (but will be made available right after I load my file to BB). I am loading this assistance
                to BB so that other organizations or I may apply UAOP against it in the future.
            </Paragraph>
        ),
    },
};
