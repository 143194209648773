import React, { FC, useEffect, useState } from 'react';
import FeatureButton from 'src/utils/FeatureButton';
import { ManagerPermission } from 'src/permission-profiles/permission';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { AgenciesList } from './AgenciesList';
import BreadCrumbs from '../utils/BreadCrumbs';
import { loadAgencies } from '../apiClient';

const AgenciesPage: FC = () => {
    const [agencies, setAgencies] = useState([]);
    const auth = useSelector((state: State) => state.auth);

    const fetchAgencies = async () => {
        const result = await loadAgencies();
        setAgencies(result);
    };

    useEffect(() => {
        fetchAgencies();
    }, []);

    return (
        <>
            <div className="row align-items-center mb-3">
                <div className="col-sm-6">
                    <BreadCrumbs path={'/agencies'} />
                </div>
                <div className="col-sm-6 d-flex justify-content-end">
                    <FeatureButton
                        linkTo={'/agencies/new'}
                        manager={auth.manager}
                        restrictedToFeatures={[ManagerPermission.agencyPark]}
                        title={'Add Agency'}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <AgenciesList agencies={agencies} onAuthorize={fetchAgencies} />
                </div>
            </div>
        </>
    );
};

export default AgenciesPage;
