import * as React from 'react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import State from '@wfp-root-app/store/state';

import { UaopFlags } from '../../DryRunDialog';
import { useStepperContext } from '../StepperContext';
import { CheckBoxContainer, Paragraph, isEnumValue } from '../utils/utils';
import { getTextByMetaCategoryAndFlag } from '../utils/uaopFlags';
import { getRecommendedUaopFlagPerMetaCategory } from '../utils/metaCategory';
import { MetaCategories } from '../../../../country/types';

interface DeduplicationOptionsStepProps {
    selectedCategory: string;
    selectedMetaCategory: MetaCategories;
    selectedFlag: UaopFlags;
    onSelectChange: (flag: UaopFlags) => void;
}

const DeduplicationOptionsStep: FC<DeduplicationOptionsStepProps> = (props) => {
    const { enableNextStep, disableNextStep } = useStepperContext();
    const { categoriesToDeduplicationFlags, categoryConfig } = useSelector(
        (state: State) => state.appConfig.entitlementsConfig
    );
    const currentCategoryConfig = useMemo(() => {
        return categoryConfig.find((category) => category.category === props.selectedCategory);
    }, [categoryConfig, props.selectedCategory]);
    const recommendedFlagForSelectedCategory = useMemo(() => {
        if (isEnumValue(UaopFlags, currentCategoryConfig?.recommendedFlagForDeduplication)) {
            return currentCategoryConfig.recommendedFlagForDeduplication;
        }

        return getRecommendedUaopFlagPerMetaCategory(props.selectedMetaCategory);
    }, [props.selectedMetaCategory]);

    const allowedDeduplicationFlags = useMemo<UaopFlags[]>(
        () => categoriesToDeduplicationFlags[props.selectedCategory],
        [props.selectedCategory, categoriesToDeduplicationFlags]
    );

    useEffect(() => {
        props.selectedFlag ? enableNextStep() : disableNextStep();
    }, [props.selectedFlag]);

    const renderIncrementalShiftPeriodV2 = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.IncrementalShiftPeriodV2}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalShiftPeriodV2)}
                        type="radio"
                    />{' '}
                    Incremental: Shift Period
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.IncrementalShiftPeriodV2)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderIncrementalDeltaAmountV2 = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.IncrementalDeltaAmountV2}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalDeltaAmountV2)}
                        type="radio"
                    />{' '}
                    Incremental: Delta Amount
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.IncrementalDeltaAmountV2)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderIncrementalAnyAmount = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.IncrementalAnyAmount}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalAnyAmount)}
                        type="radio"
                    />{' '}
                    Incremental: Any Amount{' '}
                    {recommendedFlagForSelectedCategory === UaopFlags.IncrementalAnyAmount ? '(Recommended)' : ''}
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.IncrementalAnyAmount)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderIncrementalAnyAmountWithAllowedOverlap = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.IncrementalAnyAmountWithAllowedOverlap}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.IncrementalAnyAmountWithAllowedOverlap)}
                        type="radio"
                    />{' '}
                    Incremental: Intended Assistance Overlap{' '}
                    {recommendedFlagForSelectedCategory === UaopFlags.IncrementalAnyAmountWithAllowedOverlap
                        ? '(Recommended)'
                        : ''}
                </label>
                {getTextByMetaCategoryAndFlag(
                    props.selectedMetaCategory,
                    UaopFlags.IncrementalAnyAmountWithAllowedOverlap
                )}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderTotalAnyAmount = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.TotalAnyAmount}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.TotalAnyAmount)}
                        type="radio"
                    />{' '}
                    Total: Any Amount
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.TotalAnyAmount)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderTotalDeltaAmountV2 = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.TotalDeltaAmountV2}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.TotalDeltaAmountV2)}
                        type="radio"
                    />{' '}
                    Total: Delta Amount
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.TotalDeltaAmountV2)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderNoneHistorical = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.NoneHistorical}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.NoneHistorical)}
                        type="radio"
                    />{' '}
                    None (Historical)
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.NoneHistorical)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderNoneIntended = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.NoneIntendedAssistanceOverlap}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.NoneIntendedAssistanceOverlap)}
                        type="radio"
                    />{' '}
                    None (Intended Assistance Overlap)
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.NoneIntendedAssistanceOverlap)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const renderAssistanceOverlapSkip7 = useCallback(() => {
        return (
            <CheckBoxContainer>
                <label>
                    <input
                        checked={props.selectedFlag === UaopFlags.AssistanceOverlapSkip7}
                        name="validate-category"
                        onChange={() => props.onSelectChange(UaopFlags.AssistanceOverlapSkip7)}
                        type="radio"
                    />{' '}
                    Assistance Overlap Skip 7+ Days
                </label>
                {getTextByMetaCategoryAndFlag(props.selectedMetaCategory, UaopFlags.AssistanceOverlapSkip7)}
            </CheckBoxContainer>
        );
    }, [props.selectedFlag]);

    const mappedFlagToRenderFunction = useMemo(() => {
        return {
            [UaopFlags.IncrementalAnyAmount]: renderIncrementalAnyAmount,
            [UaopFlags.IncrementalDeltaAmountV2]: renderIncrementalDeltaAmountV2,
            [UaopFlags.IncrementalShiftPeriodV2]: renderIncrementalShiftPeriodV2,
            [UaopFlags.TotalAnyAmount]: renderTotalAnyAmount,
            [UaopFlags.TotalDeltaAmountV2]: renderTotalDeltaAmountV2,
            [UaopFlags.NoneHistorical]: renderNoneHistorical,
            [UaopFlags.NoneIntendedAssistanceOverlap]: renderNoneIntended,
            [UaopFlags.IncrementalAnyAmountWithAllowedOverlap]: renderIncrementalAnyAmountWithAllowedOverlap,
            [UaopFlags.AssistanceOverlapSkip7]: renderAssistanceOverlapSkip7,
        };
    }, [
        renderIncrementalAnyAmount,
        renderIncrementalDeltaAmountV2,
        renderIncrementalShiftPeriodV2,
        renderTotalAnyAmount,
        renderTotalDeltaAmountV2,
        renderNoneHistorical,
        renderNoneIntended,
        renderAssistanceOverlapSkip7,
    ]);

    const optionsToBeRendered = useMemo(() => {
        return allowedDeduplicationFlags.map((flag) => mappedFlagToRenderFunction[flag]);
    }, [mappedFlagToRenderFunction, allowedDeduplicationFlags]);

    return (
        <>
            <h6
                style={{
                    marginTop: '32px',
                }}
            >
                What type of UAOP would you like to perform (only one selection possible):
            </h6>
            <Paragraph>
                Note: for each UAOP option, the assistance period must be the same for all the rows in your file.
            </Paragraph>
            {optionsToBeRendered.map((renderFunc) => renderFunc())}
        </>
    );
};

DeduplicationOptionsStep.displayName = 'DeduplicationOptionsStep';

export { DeduplicationOptionsStep };
