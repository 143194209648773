import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { ProtectedRoute } from '@wfp-common/auth/ProtectedRoute';
import { PermissionChecker } from '@wfp-common/auth/PermissionChecker';

import { ManagerPermission } from './permission-profiles/permission';
import { AppContainer } from './app/AppContainer';
import LoginPage from './login/LoginPage';
import ErrorPage from './app/ErrorPage';
import ResetOtpPage from './login/ResetOtpPage';
import ForceResetPasswordPage from './security/ForceResetPasswordPage';
import TaskAuthorizationsPage from './authorization/TaskAuthorizationsPage';
import { AuthorizationTaskSubPage } from './authorization/AuthorizationTaskSubPage';
import ManagersPage from './managers/ManagersPage';
import ManagerExportPage from './managers/ManagerExportPage';
import { AddManagerPage } from './managers/AddManagerPage';
import ManagerEditPage from './managers/ManagerEditPage';
import { ManagerTabRenderer } from './managers/tabs';
import ActivityLogsDetailsView from './activityLogs/details/ActivityLogsDetailsView';
import { ManagerLogExport } from './activityLogs/MenagerActivityLogExport';
import DocumentsPage from './documents/DocumentsPage';
import { UploadDocumentsForm } from './documents/UploadDocumentsForm';
import ExportedFromStatistics from './statistics/export/ExportedFromStatistics';
import AsyncTaskDetailsView from './utils/AsyncTaskDetailsView';
import ManagersMainPage from './managers/ManagersMainPage';
import { ManagerPersonalTabRenderer } from './managers/personal/ManagerPersonalTabRenderer';
import { BENEFICIARY_SUPPORT_PANEL_URL } from './partners/utils/constants';
import PartnersPage from './partners/PartnersPage';
import PartnerAddPage from './partners/PartnerAddPage';
import PartnerEditPage from './partners/PartnerEditPage';
import { BeneficiarySupportPanelTabRenderer } from './partners/tabs';
import EntitlementsPage from './entitlements/EntitlementsPage';
import { FileUploadContainer } from './beneficiaries/fileUploadPages/FileUploadContainer';
import DeduplicatedEntitlementExportPage from './entitlements/DeduplicatedEntitlementExportPage';
import TaxIdMappingExportPage from './entitlements/TaxIdMappingExportPage';
import EditBeneficiaryPage from './beneficiaries/edit/EditBeneficiaryPage';
import { EditBeneficiaryTabRenderer } from './beneficiaries/edit/EditBeneficiaryTabRenderer';
import { AssistanceContainer } from './entitlements/AssistanceContainer';
import EntitlementExportPage from './entitlements/EntitlementExportPage';
import AgenciesPage from './agencies/AgenciesPage';
import AgencyAdd from './agencies/AgencyAdd';

export const sessionAutoExpirationMessage = 'The previous session time has auto-expired.';
const country = process.env.COUNTRY || 'default';
const HomePageCountrySpecific = lazy(() => import(`./home/HomePage${country === 'default' ? '' : `.${country}`}`));

export default () => {
    return (
        <Route element={<Outlet />}>
            <Route
                element={
                    <ProtectedRoute>
                        <AppContainer />
                    </ProtectedRoute>
                }
            >
                <Route
                    element={
                        <Suspense fallback={<div>Loading...</div>}>
                            <HomePageCountrySpecific />
                        </Suspense>
                    }
                    index
                />
                <Route element={<Navigate to={'/'} />} path={'home'} />
                <Route element={<LoginPage />} path="login" />
                <Route element={<ResetOtpPage />} path="set-otp" />

                <Route element={<ForceResetPasswordPage />} path="change-password" />

                <Route
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.adjustmentsView]}>
                            <TaskAuthorizationsPage />
                        </PermissionChecker>
                    }
                    path={'adjustments/*'}
                >
                    <Route element={<AuthorizationTaskSubPage />} path=":taskType" />
                    <Route element={<ErrorPage />} path="*" />
                </Route>

                <Route element={<Outlet />} path={'admin'}>
                    <Route
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.managersManagement]}>
                                <ManagersPage />
                            </PermissionChecker>
                        }
                        index
                    />

                    <Route
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.userDownload]}>
                                <ManagerExportPage />
                            </PermissionChecker>
                        }
                        path={'download'}
                    />
                    <Route element={<AddManagerPage />} path={'new'} />

                    <Route
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.managersManagement]}>
                                <ManagerEditPage />
                            </PermissionChecker>
                        }
                        path={':managerId'}
                    >
                        <Route element={<ManagerTabRenderer />} path={':managerTab'} />
                        <Route
                            element={
                                <PermissionChecker requiredPermissions={[ManagerPermission.userActivityDownload]}>
                                    <ManagerLogExport />
                                </PermissionChecker>
                            }
                            path={':managerTab/export-activity-log'}
                        />
                        <Route element={<ActivityLogsDetailsView />} path={':managerTab/:logId'} />
                    </Route>
                </Route>

                <Route element={<DocumentsPage />} path={'documents'} />
                <Route
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.documentManagement]}>
                            <UploadDocumentsForm />
                        </PermissionChecker>
                    }
                    path={'documents/create'}
                />

                <Route element={<AgenciesPage />} path={'agencies'} />

                <Route element={<AgencyAdd />} path={'agencies/new'} />

                <Route
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesRead]}>
                            <Outlet />
                        </PermissionChecker>
                    }
                    path={'entitlements'}
                >
                    <Route element={<EntitlementsPage />} index />
                    <Route element={<FileUploadContainer />} path={'import'} />

                    <Route
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <AssistanceContainer />
                            </PermissionChecker>
                        }
                        path={'download'}
                    />

                    <Route
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <TaxIdMappingExportPage />
                            </PermissionChecker>
                        }
                        path={'download-mapping'}
                    />

                    <Route element={<EditBeneficiaryPage />} path={'download/beneficiary'}>
                        <Route element={<EditBeneficiaryTabRenderer />} path={':beneficiaryTab'} />
                    </Route>

                    <Route
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <DeduplicatedEntitlementExportPage />
                            </PermissionChecker>
                        }
                        path="download/deduplicated-entitlements/file"
                    />

                    <Route
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <EntitlementExportPage />
                            </PermissionChecker>
                        }
                        path="download/entitlements/file"
                    />

                    <Route
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                                <DeduplicatedEntitlementExportPage />
                            </PermissionChecker>
                        }
                        path={'download'}
                    />

                    <Route element={<EditBeneficiaryPage />} path={'beneficiary'}>
                        <Route element={<EditBeneficiaryTabRenderer />} path={':beneficiaryTab'} />
                    </Route>
                </Route>

                <Route
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.partnersRead]}>
                            <Outlet />
                        </PermissionChecker>
                    }
                    path={BENEFICIARY_SUPPORT_PANEL_URL}
                >
                    <Route element={<PartnersPage />} index />

                    <Route
                        element={
                            <PermissionChecker requiredPermissions={[ManagerPermission.partnerPark]}>
                                <PartnerAddPage />
                            </PermissionChecker>
                        }
                        path={'new'}
                    />

                    <Route element={<PartnerEditPage />} path={':partnerId'}>
                        <Route element={<BeneficiarySupportPanelTabRenderer />} path={':baneSupportTab'}>
                            <Route path={':supportTabSubView'} />
                        </Route>
                    </Route>
                </Route>

                <Route
                    element={
                        <PermissionChecker requiredPermissions={[ManagerPermission.beneficiariesDownload]}>
                            <ExportedFromStatistics />
                        </PermissionChecker>
                    }
                    path={'exported-from-statistics'}
                />

                <Route element={<AsyncTaskDetailsView />} path={'tasks/:type/:taskId'} />

                <Route element={<ManagersMainPage />} path={'personal'}>
                    <Route element={<ManagerPersonalTabRenderer />} path={':personalTab'} />
                </Route>

                <Route element={<ErrorPage />} path="*" />
            </Route>
        </Route>
    );
};
