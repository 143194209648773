import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addAgency } from 'src/apiClient';
import { errorAction, successAction } from 'src/utils/notifications';
import BreadCrumbs from '../utils/BreadCrumbs';

const AgencyAdd: FC = () => {
    const [name, setName] = useState('');
    const [abbreviation, setAbbreviation] = useState('');
    const dispatch = useDispatch();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await addAgency({ name: name.trim(), abbreviation: abbreviation.trim() });
            dispatch(successAction('Agency created successfully'));
        } catch (error) {
            if (error.status === 409) {
                dispatch(errorAction('Agency with this abbreviation already exists'));
            } else {
                dispatch(errorAction(error));
            }
        }
    };

    return (
        <main>
            <BreadCrumbs path={'/agencies/new'} />
            <h2>Add Agency</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        className="form-control"
                        id="name"
                        onChange={(e) => setName(e.target.value)}
                        required
                        type="text"
                        value={name}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="abbreviation">Abbreviation</label>
                    <input
                        className="form-control"
                        id="abbreviation"
                        onChange={(e) => setAbbreviation(e.target.value)}
                        required
                        type="text"
                        value={abbreviation}
                    />
                </div>
                <button className="btn btn-primary" type="submit">
                    Add Agency
                </button>
            </form>
        </main>
    );
};

export default AgencyAdd;
