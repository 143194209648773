export enum ManagerPermission {
    vendorsRead = 'vendorsRead',
    vendorsPost = 'vendorsPost',
    vendorsPark = 'vendorsPark',
    vendorsDownload = 'vendorsDownload',
    vendorPasswordReset = 'vendorPasswordReset',
    vendorUserPark = 'vendorUserPark',
    vendorUserPost = 'vendorUserPost',

    partnersRead = 'partnersRead',
    partnerDownload = 'partnersDownload',
    partnerPark = 'partnerPark',
    partnerPost = 'partnerPost',
    partnerUserPark = 'partnerUserPark',
    partnerUserPost = 'partnerUserPost',
    partnerUserPasswordReset = 'partnerUserPasswordReset',

    beneficiariesDownload = 'beneficiariesDownload',
    beneficiariesRead = 'beneficiariesRead',
    beneficiariesPost = 'beneficiariesPost',
    beneficiariesPark = 'beneficiariesPark',
    beneficiariesEntitlementPark = 'beneficiariesEntitlementPark',
    beneficiariesEntitlementVerify = 'beneficiariesEntitlementVerify',
    beneficiariesMassBlockPark = 'beneficiariesMassBlockPark',
    beneficiariesMassBlockPost = 'beneficiariesMassBlockPost',
    beneficiariesMassUnblockPark = 'beneficiariesMassUnblockPark',
    beneficiariesMassUnblockPost = 'beneficiariesMassUnblockPost',
    beneficiariesSingleBlockPark = 'beneficiariesSingleBlockPark',
    beneficiariesSingleBlockPost = 'beneficiariesSingleBlockPost',
    beneficiariesSingleUnblockPark = 'beneficiariesSingleUnblockPark',
    beneficiariesSingleUnblockPost = 'beneficiariesSingleUnblockPost',
    beneficiariesMassZeroPark = 'beneficiariesMassZeroPark',
    beneficiariesMassZeroPost = 'beneficiariesMassZeroPost',
    beneficiariesSingleZeroPark = 'beneficiariesSingleZeroPark',
    beneficiariesSingleZeroPost = 'beneficiariesSingleZeroPost',
    beneficiariesCommentView = 'beneficiariesCommentView',
    beneficiariesAddComment = 'beneficiariesAddComment',
    beneficiariesSignLevelOne = 'beneficiariesSignLevelOne',
    beneficiariesSignLevelTwo = 'beneficiariesSignLevelTwo',
    alternativeCollectorsImportPark = 'alternativeCollectorsImportPark',
    alternativeCollectorsImportPost = 'alternativeCollectorsImportPost',
    removeAlternativeCollectorsImportPark = 'removeAlternativeCollectorsImportPark',
    removeAlternativeCollectorsImportPost = 'removeAlternativeCollectorsImportPost',
    alternativeCollectorStatusChangePark = 'alternativeCollectorStatusChangePark',
    alternativeCollectorStatusChangePost = 'alternativeCollectorStatusChangePost',

    paymentsRead = 'paymentsRead',
    paymentsGeneratePark = 'paymentsGeneratePark',
    paymentsGeneratePost = 'paymentsGeneratePost',
    paymentsPaidPark = 'paymentsPaidPark',
    paymentsPaidPost = 'paymentsPaidPost',
    paymentIssuePark = 'paymentIssuePark',
    paymentIssuePost = 'paymentIssuePost',
    paymentsSummaryDownload = 'paymentsSummaryDownload',
    paymentsDetailsDownload = 'paymentsDetailsDownload',

    userActivityView = 'userActivityView',
    userActivityDownload = 'userActivityDownload',
    operationActivityView = 'operationActivityView',
    operationActivityDownload = 'operationActivityDownload',

    managersManagement = 'managersManagement',
    managersManagementPark = 'managersManagementPark',
    managersManagementPost = 'managersManagementPost',
    userDownload = 'userDownload',
    userPasswordReset = 'userPasswordReset',
    userOTPReset = 'userOTPReset',
    viewAndDownloadUserPermissionsFile = 'viewAndDownloadUserPermissionsFile',

    adjustmentsView = 'adjustmentsView',
    adjustmentsDownload = 'adjustmentsDownload',

    transactionRequestRead = 'transactionRequestRead',
    transactionRequestPark = 'transactionRequestPark',
    transactionRequestPost = 'transactionRequestPost',
    transactionsDownload = 'transactionsDownload',

    otpStatusChangePark = 'otpStatusChangePark',
    otpStatusChangePost = 'otpStatusChangePost',
    changeOtpStatus = 'changeOtpStatus',
    otpEmail = 'otpEmail',

    viewAndDownloadSummaryReports = 'viewAndDownloadSummaryReports',
    viewAndDownloadDetailedReports = 'viewAndDownloadDetailedReports',

    documentManagement = 'documentManagement',

    productsRead = 'productsRead',
    productsPark = 'productsPark',
    productsVerify = 'productsVerify',
    productsPostLevelOne = 'productsPostLevelOne',
    productsPostLevelTwo = 'productsPostLevelTwo',
    productsLimitationRead = 'productsLimitationRead',

    profilesRead = 'profilesRead',
    profilesPark = 'profilesPark',
    profilesPost = 'profilesPost',

    agencyPark = 'agencyPark',
    agencyPost = 'agencyPost',
}
